import gql from "graphql-tag";

export const USERS_SELECT_QUERY = gql`
  query UsersSelect(
    $filter: UserFilter!
    $sorting: [UserSort!]
    $paging: OffsetPaging!
  ) {
    users(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      nodes {
        id
        name
        avatarUrl
      }
    }
  }
`;

export const DASHBOARD_CALENDAR_UPCOMING_EVENTS_QUERY = gql`
  query DashboardCalendarUpcomingEvents(
    $filter: EventFilter!
    $sorting: [EventSort!]
    $paging: OffsetPaging!
  ) {
    events(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      nodes {
        id
        title
        color
        startDate
        endDate
      }
    }
  }
`;

export const TASK_STAGES_SELECT_QUERY = gql`
  query TaskStagesSelect(
    $filter: TaskStageFilter!
    $sorting: [TaskStageSort!]
    $paging: OffsetPaging!
  ) {
    taskStages(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      nodes {
        id
        title
      }
    }
  }
`;

export const TASKS_STAGES_QUERY = gql`
  query TaskStages(
    $filter: TaskStageFilter!
    $sorting: [TaskStageSort!]
    $paging: OffsetPaging!
  ) {
    taskStages(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      nodes {
        id
        title
      }
    }
  }
`;

export const TASKS_QUERY = gql`
  query Tasks(
    $filter: TaskFilter!
    $sorting: [TaskSort!]
    $paging: OffsetPaging!
  ) {
    tasks(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      nodes {
        id
        title
        description
        dueDate
        completed
        stageId
        users {
          id
          name
          avatarUrl
        }
        createdAt
        updatedAt
      }
    }
  }
`;

// Query to get user settings
export const ACCOUNT_SETTINGS_GET_USER_QUERY = gql`
  query AccountSettingsGetUser($id: ID!) {
    user(id: $id) {
      id
      email
      timezone
    }
  }
`;

// Mutation to update user settings
export const ACCOUNT_SETTINGS_UPDATE_USER_MUTATION = gql`
  mutation AccountSettingsUpdateUser($input: UpdateOneUserInput!) {
    updateOneUser(input: $input) {
      id
      email
      timezone
    }
  }
`;

export const DASHBOARD_DEALS_CHART_QUERY = gql`
  query DashboardDealsChart(
    $filter: DealStageFilter!
    $sorting: [DealStageSort!]
    $paging: OffsetPaging!
  ) {
    dealStages(filter: $filter, sorting: $sorting, paging: $paging) {
      nodes {
        title
        dealsAggregate {
          groupBy {
            closeDateMonth
            closeDateYear
          }
          sum {
            value
          }
        }
      }
    }
  }
`;

export const DASHBOARD_TOTAL_COUNTS_QUERY = gql`
  query DashboardTotalCounts {
    companies {
      totalCount
    }
    contacts {
      totalCount
    }
    deals {
      totalCount
    }
  }
`;

export const DASHBOARD_LATEST_ACTIVITIES_AUDITS_QUERY = gql`
  query DashboardLatestActivitiesAudits(
    $filter: AuditFilter!
    $sorting: [AuditSort!]
    $paging: OffsetPaging!
  ) {
    audits(filter: $filter, sorting: $sorting, paging: $paging) {
      nodes {
        id
        action
        targetEntity
        targetId
        changes {
          field
          from
          to
        }
        createdAt
        user {
          id
          name
          avatarUrl
        }
      }
    }
  }
`;

export const DASHBOARD_LATEST_ACTIVITIES_DEALS_QUERY = gql`
  query DashboardLatestActivitiesDeals(
    $filter: DealFilter!
    $sorting: [DealSort!]
    $paging: OffsetPaging!
  ) {
    deals(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      nodes {
        id
        title
        stage {
          id
          title
        }
        company {
          id
          name
          avatarUrl
        }
        createdAt
      }
    }
  }
`;

export const COMPANIES_LIST_QUERY = gql`
  query CompaniesList(
    $filter: CompanyFilter!
    $sorting: [CompanySort!]
    $paging: OffsetPaging!
  ) {
    companies(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      nodes {
        id
        name
        avatarUrl
        totalRevenue
        industry
        dealsAggregate {
          sum {
            value
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_QUERY = gql`
  query GetCompany($id: ID!) {
    company(id: $id) {
      id
      name
      totalRevenue
      industry
      companySize
      businessType
      country
      website
      avatarUrl
      salesOwner {
        id
        name
        avatarUrl
      }
    }
  }
`;

export const GET_COMPANIES_QUERY = gql`
  query GetCompanies($filter: CompanyFilter, $sorting: [CompanySort!], $paging: OffsetPaging!) {
    companies(filter: $filter, sorting: $sorting, paging: $paging) {
      nodes {
        id
        name
        totalRevenue
        industry
        companySize
        businessType
        country
        website
        avatarUrl
      }
      totalCount
    }
  }
`;

// Mutation to create a new task in Kanban
export const KANBAN_CREATE_TASK_MUTATION = gql`
  mutation KanbanCreateTask($input: CreateOneTaskInput!) {
    createOneTask(input: $input) {
      id
      title
      description
      dueDate
      completed
      stageId
    }
  }
`;

export const COMPANY_CONTACTS_TABLE_QUERY = gql`
  query CompanyContactsTable(
    $filter: ContactFilter!
    $sorting: [ContactSort!]
    $paging: OffsetPaging!
  ) {
    contacts(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      nodes {
        id
        name
        avatarUrl
        jobTitle
        email
        phone
        status
      }
    }
  }
`;

export const DASHBOARD_REALTIME_QUERY = gql`
  subscription OnDashboardUpdate($userId: ID!) {
    dashboardUpdates(userId: $userId) {
      deals {
        id
        title
        status
        updatedAt
      }
      contacts {
        id
        name
        email
        updatedAt
      }
      companies {
        id
        name
        updatedAt
      }
    }
  }
`;