import React from 'react';
import { useDraggable, UseDraggableArguments } from "@dnd-kit/core";

interface Props {
    id: string;
    data?: UseDraggableArguments['data'];
}

const KanbanItem = ({ children, id, data }: React.PropsWithChildren<Props>) => {
    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id: id,
        data: data
    });

    return (
        <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={{
                opacity: isDragging ? 0.5 : 1,
                borderRadius: '8px',
                position: 'relative',
                cursor: 'grab',
                boxShadow: isDragging ? 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px' : 'none',
            }}
        >
            {children}
        </div>
    );
};

export default KanbanItem;