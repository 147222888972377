// /Users/luisjean/Desktop/Expedient/Expedient/src/components/skeleton/project-card.tsx
import { Card, Skeleton } from "antd";
import React from "react";

export const ProjectCardSkeleton = () => {
  return (
    <Card
      style={{
        height: "100%",
        padding: "16px",
      }}
    >
      <Skeleton.Input
        size="small"
        block
        style={{ height: "22px" }}
      />
      <Skeleton.Input
        size="small"
        block
        style={{ height: "16px", marginTop: "8px" }}
      />
      <Skeleton.Input
        size="small"
        block
        style={{ height: "16px", marginTop: "8px" }}
      />
    </Card>
  );
};