import graphqlDataProvider, {
     GraphQLClient,
     liveProvider as graphqlLiveProvider,
    } from "@refinedev/nestjs-query";
import { fetchWrapper } from "./fetch-wrapper";
import { createClient } from "graphql-ws";
import { ACCESS_TOKEN_KEY } from '../../constants';


export const API_BASE_URL = import.meta.env.PROD 
  ? 'https://api.expedient.one'
  : 'http://localhost:5000';
export const API_URL = `${API_BASE_URL}/graphql`;
export const WS_URL = import.meta.env.PROD
  ? 'wss://api.expedient.one/graphql'
  : 'ws://localhost:5000/graphql';

export const client = new GraphQLClient(API_URL, {
    fetch: (url: string, options: RequestInit) => {
        try {
            return fetchWrapper(url, options);
        } catch (error) {
            return Promise.reject(error);
        }
    },
});

export const wsClient = typeof window !== "undefined" 
? createClient({
    url: WS_URL,
    connectionParams: () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        }
    }
}) : undefined;


export const dataProvider = graphqlDataProvider(client);
export const liveProvider = wsClient ? graphqlLiveProvider(wsClient) : undefined;