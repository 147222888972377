import { useGetIdentity } from '@refinedev/core'
import { Popover, Button, Typography } from 'antd'
import React, { useState } from 'react'
import CustomAvatar from '../custom-avatar'
import { AccountSettings } from './account-settings'
import type { User } from '../../graphql/schema.types';
import { SettingOutlined } from '@ant-design/icons/lib/icons';

const { Text } = Typography;

const CurrentUser = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: user } = useGetIdentity<User>()

  const content = (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Text
        strong
        style={{ padding: '12px 20px' }}      
      >
        {user?.name}
      </Text>
      <div>
        <Button
          style={{ textAlign: 'left' }}
          icon={<SettingOutlined />}
          type="text"
          block
          onClick={() => setIsOpen(true)}
        >
          Account Settings
        </Button>
      </div>
    </div>
  )

  
  return (
    <>
    <Popover
        placement='bottomRight'
        trigger="click"
        overlayInnerStyle={{ padding: 0 }}
        overlayStyle={{ zIndex: 9999 }}
        content={content}
        >
          <CustomAvatar 
          name={user?.name}
          src={user?.avatarUrl}
          size="default"
          style={{ cursor: 'pointer' }}
        />
    </Popover>
    {user && (
    <AccountSettings
      opened={isOpen}
      setOpened={setIsOpen}
      userId={user.id}
    />
    )}
    </>
  )
}

export default CurrentUser