import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useGetIdentity } from "@refinedev/core";

interface ProtectedTasksRouteProps {
  children: React.ReactNode;
}

interface User {
  isSubscribed?: boolean;
}

const ProtectedTasksRoute: React.FC<ProtectedTasksRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const { data: user } = useGetIdentity<User>();

  useEffect(() => {
    if (!user?.isSubscribed) {
      navigate("/expedient");
    }
  }, [user, navigate]);

  if (!user?.isSubscribed) {
    return null;
  }

  return <>{children}</>;
};

export default ProtectedTasksRoute;