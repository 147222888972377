/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { AuthBindings } from "@refinedev/core";

// Add this interface
export interface AuthCredentials {
  email: string;
  password: string;
}

// Add demo credentials if you're using them
export const authCredentials = {
  email: "demo@expedient.one",
  password: "demodemo",
};

export const authProvider: AuthBindings = {
  login: async ({ email, password }: AuthCredentials) => {
    console.log('Login attempt with:', { email }); // Debug log
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/auth/login`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Login response:', response.data); // Debug log

      if (response.data?.token) {
        localStorage.setItem("access_token", response.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
        return {
          success: true,
          redirectTo: "/",
        };
      }

      throw new Error('No token received');

    } catch (error: any) {
      console.error('Login error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      return {
        success: false,
        error: {
          message: error.response?.data?.message || "Login failed",
          name: "Invalid credentials",
        },
      };
    }
  },
  
  register: async ({ email, password }: AuthCredentials) => {
    console.log('Register attempt with:', { email }); // Debug log
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/auth/register`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Register response:', response.data); // Debug log

      if (response.data?.token) {
        localStorage.setItem("access_token", response.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
        return {
          success: true,
          redirectTo: "/",
        };
      }

      throw new Error('No token received');

    } catch (error: any) {
      console.error('Register error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      return {
        success: false,
        error: {
          message: error.response?.data?.message || "Registration failed",
          name: "Invalid registration",
        },
      };
    }
  },

  logout: async () => {
    localStorage.removeItem("access_token");
    return {
      success: true,
      redirectTo: "/login",
    };
  },

  check: async () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      return {
        authenticated: false,
        redirectTo: "/login",
      };
    }

    try {
      // Verify token with backend
      await axios.get(`${import.meta.env.VITE_API_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        authenticated: true,
      };
    } catch (error) {
      return {
        authenticated: false,
        redirectTo: "/login",
      };
    }
  },

  getIdentity: async () => {
    const token = localStorage.getItem("access_token");
    if (!token) return null;

    try {
      const response = await axios.get(`${import.meta.env.VITE_API_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('GetIdentity response:', response.data); // Debug log

      const user = response.data;
      return {
        ...user,
        avatar: user.avatarUrl,
      };
    } catch (error) {
      console.error('GetIdentity error:', error); // Debug log
      return null;
    }
  },

  onError: async (error) => {
    console.error('Auth error:', error); // Debug log
    if (error.status === 401 || error.status === 403) {
      return {
        logout: true,
        redirectTo: "/login",
      };
    }
    return {};
  },
};