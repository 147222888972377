import { GraphQLFormattedError } from "graphql";
import { ACCESS_TOKEN_KEY } from '../../constants';

type Error = {
    message: string;
    statusCode: number;
}



const customFetch = async (url: string, options: RequestInit) => {

    const headers = options.headers as Record<string, string>;

    return await fetch(url, {
        ...options,
        headers: {
            ...headers,
            Authorization: headers?.Authorization || `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
            "Content-Type": "application/json",
            "Apollo-Require-Preflight": "true",
        },
    });
};


const getGraphQLErrors = (body: Record<"errors", GraphQLFormattedError[] | undefined>):
Error | null => {
    if(!body) {
        return {
            message: "An unknown error occurred",
            statusCode: 500,
        }
    }
    if("errors" in body) {
        const errors = body?.errors;

        const messages = errors?.map((error) => error?.message)?.join("");
        const code = errors?.[0]?.extensions?.code;

        return {
            message: messages || JSON.stringify(errors),
            statusCode: code || 500,
        }
    }

    return null;
}

export const fetchWrapper = async (url: string, options: RequestInit) => {
    const response = await customFetch(url, options);
    
    const responseClone = response.clone();
    const body = await responseClone.json();

    const error = getGraphQLErrors(body);
    if(error) {
        throw error;
    }

    return response;
}