import { getNameInitials } from '@/utilities/get-name-initials';
import { Avatar as AntdAvatar, AvatarProps } from 'antd';
import React from 'react';

type Props = AvatarProps & {
    name?: string;
}

const CustomAvatar = ({ name, style, ...rest }: Props) => {
    return <AntdAvatar style={{
        backgroundColor: '#87d068',
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        ...style,
        }}
        {...rest}
    >
        {getNameInitials(name || '')}
    </AntdAvatar>
}

export default CustomAvatar;