import React from 'react';
import { AuthPage } from "@refinedev/antd";
import { authCredentials } from "../../providers/auth";
import { useLogin } from "@refinedev/core"; // Assuming you're using refinedev
import welcome from '../../assets/welcome.svg';

export const Login = () => {
  const { mutate: login } = useLogin();

  const handleLogin = async (values: { email: string; password: string }) => {
    try {
      login(values);
      // Redirect or show success message
    } catch (error) {
      // Handle error (e.g., show error message)
    }
  };

  return (
    <>
      <img 
        src={welcome} 
        alt="Expedient Logo" 
        style={{ 
        height: '189px',  // Reduced size
        width: 'auto',
        objectFit: 'contain',
        }} 
      />
      <AuthPage
        type="login"
        formProps={{
          initialValues: authCredentials,
          onFinish: handleLogin, // Handle form submission
        }}
      />
    </>
  );
};
