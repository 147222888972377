import { DollarOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';
import { Text } from '@/components/text';
import { Area, AreaConfig } from '@ant-design/plots';
import { useList } from '@refinedev/core';
import { DASHBOARD_DEALS_CHART_QUERY } from '@/graphql/queries';
import { DashboardDealsChartQuery } from '@/graphql/types';
import { GetFieldsFromList } from '@refinedev/nestjs-query';

// Define more precise types for the data structure
type DealStageAggregate = {
  groupBy?: {
    closeDateMonth?: string | null;
    closeDateYear?: string | null;
  } | null;
  sum?: {
    value?: number | null;
  } | null;
};

type DealStageData = {
  title: string;
  dealsAggregate: DealStageAggregate[];
};

const mapDealsData = (stages: DealStageData[]) => {
  return stages.flatMap(stage => 
    stage.dealsAggregate.map(aggregate => ({
      timeText: `${aggregate.groupBy?.closeDateMonth ?? ''}-${aggregate.groupBy?.closeDateYear ?? ''}`,
      value: aggregate.sum?.value ?? 0,
      state: stage.title,
    }))
  );
};

const DealsChart = () => {
  const { data } = useList<GetFieldsFromList<DashboardDealsChartQuery>>({
    resource: 'dealStages',
    filters: [
      {
        field: 'title',
        operator: 'in',
        value: ['WON', 'LOST']
      }
    ],
    meta: {
      gqlQuery: DASHBOARD_DEALS_CHART_QUERY,
    },
  });

  const dealData = React.useMemo(() => {
    return mapDealsData(data?.data as DealStageData[] || []);
  }, [data?.data]);

  const config: AreaConfig = {
    data: dealData,
    xField: 'timeText',
    yField: 'value',
    isStack: false,
    seriesField: 'state',
    animation: true,
    startOnZero: false,
    smooth: true,
    legend: {
      offsetY: -6
    },
    height: 325,
    areaStyle: { fillOpacity: 0.1 },
    xAxis: {
      range: [0, 1],
    },
    yAxis: {
      tickCount: 4,
      label: {
        formatter: (v: string) => `$${Number(v) / 1000}k`
      }
    },
    tooltip: {
      formatter: (datum) => {
        const data = datum as { state: string; value: number };
        return {
          name: data.state,
          value: `$${Number(data.value) / 1000}k`
        };
      },
    },
  };

  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ padding: '8px 16px' }}
      bodyStyle={{ padding: '24px 24px 0 24px' }}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <DollarOutlined />
          <Text size="sm" style={{ marginLeft: '0.5rem' }}>
            Deals
          </Text>
        </div>
      }
    >
      <Area {...config} />
    </Card>
  );
};

export default DealsChart;