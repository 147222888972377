import React from 'react';

const Terms = () => {
    return (
        <div>
            <h1>Terms of Service</h1>
            <p>Your terms of service content goes here.</p>
        </div>
    );
};

export default Terms;