import React from 'react';
import { Layout } from 'antd';

const Footer = () => {
    return (
        <Layout.Footer style={{ textAlign: 'center' }}>
            <div>
                © Copyright 2024 Expedient AI, Inc. All rights reserved
            </div>
            <div>
                <a href="/terms">Terms of Service</a>
            </div>
        </Layout.Footer>
    );
};

export default Footer;