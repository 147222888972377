import React from 'react'
import { Card, Skeleton } from 'antd'

export const KanbanColumnSkeleton: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Card
      title={<Skeleton.Input style={{ width: 150 }} active size="small" />}
      style={{ width: 300, marginRight: 16 }}
    >
      <Skeleton.Input style={{ width: '100%', marginBottom: 16 }} active size="small" />
      {children}
    </Card>
  )
}
export default KanbanColumnSkeleton