import gql from "graphql-tag";

// Mutation to create task
export const CREATE_TASK_MUTATION = gql`
  mutation CreateOneTask($input: CreateOneTaskInput!) {
    createOneTask(input: $input) {
      id
      title
      description
      dueDate
      completed
      stageId
      updatedAt
    }
  }
`;

// Mutation to create company
export const CREATE_COMPANY_MUTATION = gql`
  mutation CreateOneCompany($input: CreateOneCompanyInput!) {
    createOneCompany(input: $input) {
      id
      name
      salesOwner {
        id
      }
    }
  }
`;

// Mutation to update company details
export const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateOneCompany($input: UpdateOneCompanyInput!) {
    updateOneCompany(input: $input) {
      id
      name
      totalRevenue
      industry
      companySize
      businessType
      country
      website
      avatarUrl
      salesOwner {
        id
        name
        avatarUrl
      }
    }
  }
`;

// Mutation to update task stage of task 
export const UPDATE_TASK_STAGE_MUTATION = gql`
  mutation UpdateOneTask($input: UpdateOneTaskInput!) {
    updateOneTask(input: $input) {
      id
      title
      stageId
    }
  }
`;

// Mutation to update task
export const UPDATE_TASK_MUTATION = gql`
  mutation UpdateOneTask($input: UpdateOneTaskInput!) {
    updateOneTask(input: $input) {
      id
      title
      description
      dueDate
      completed
      stageId
      updatedAt
    }
  }
`;