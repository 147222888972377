// /Users/luisjean/Desktop/Expedient/Expedient/src/components/skeleton/latest-activities.tsx
import React from "react";
import { List, Skeleton } from "antd";

export const LatestActivitiesSkeleton = () => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={Array.from({ length: 5 }).map((_, index) => ({
        id: index,
      }))}
    >
      {Array.from({ length: 5 }).map((_, index) => (
        <List.Item key={index}>
          <List.Item.Meta
            avatar={<Skeleton.Avatar size="small" shape="square" />}
            title={
              <Skeleton.Input
                size="small"
                block
                style={{ height: "22px" }}
              />
            }
            description={
              <Skeleton.Input
                size="small"
                block
                style={{ height: "16px" }}
              />
            }
          />
        </List.Item>
      ))}
    </List>
  );
};