/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

interface DecodedToken {
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': string[];
    'x-hasura-default-role': string;
    'x-hasura-user-id': string;
  };
  exp: number;
  email: string;
}

interface User {
  id: string;
  email: string;
  isSubscribed: boolean;
  roles: string[];
}

interface AuthContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const defaultContext: AuthContextType = {
  user: null,
  setUser: () => {
    console.warn('AuthContext not initialized');
  },
  login: async () => {
    console.warn('AuthContext not initialized');
  },
  logout: () => {
    console.warn('AuthContext not initialized');
  },
};

const AuthContext = createContext<AuthContextType>(defaultContext);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/api/auth/login`, { 
      email, 
      password 
    });
    
    if (response?.data?.token) {
      const token = response.data.token;
      localStorage.setItem('accessToken', token);
      
      try {
        const decoded = jwtDecode<DecodedToken>(token);
        setUser({
          id: decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'],
          email: decoded.email,
          isSubscribed: decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes('user_subscribed'),
          roles: decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
        });
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } catch (error) {
        console.error('Invalid token during login:', error);
        logout();
      }
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

  const logout = () => {
    localStorage.removeItem('accessToken');
    setUser(null);
    delete axios.defaults.headers.common['Authorization'];
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      try {
        const decoded = jwtDecode<DecodedToken>(token);
        if (decoded.exp * 1000 > Date.now()) {
          setUser({
            id: decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'],
            email: decoded.email,
            isSubscribed: decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes('user_subscribed'),
            roles: decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
          });
          // Set default auth header for axios
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
          console.warn('Token expired');
          logout();
        }
      } catch (error) {
        console.error('Invalid token during initialization:', error);
        logout();
      }
    }
  }, []);
  return (
    <AuthContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => useContext(AuthContext);