import React from 'react';
import { Link } from 'react-router-dom';

const CancelPage: React.FC = () => {
  return (
    <div style={styles.container}>
      <h2>Subscription Canceled</h2>
      <p>Your subscription process was canceled. You can try subscribing again.</p>
      <Link to="/expedient" style={styles.link}>
        Subscribe Now
      </Link>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px',
  },
  link: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#dc3545',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
  },
};

export default CancelPage;