/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const SuccessPage: React.FC = () => {
  const location = useLocation();
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const query = new URLSearchParams(location.search);
  const sessionId = query.get('session_id');

  useEffect(() => {
    const fetchSession = async () => {
      if (sessionId && user) {
        try {
          const response = await axios.get(`${import.meta.env.VITE_API_URL}/subscription/verify/${sessionId}`);
          if (response.data.isActive) {
            // Update user subscription status in context
            setUser({ ...user, isSubscribed: true });
            localStorage.setItem('access_token', localStorage.getItem('access_token') || '');
          } else {
            setError('Subscription verification failed.');
          }
        } catch (err: any) {
          console.error(err);
          setError('Failed to verify subscription.');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchSession();
  }, [sessionId, user, setUser]);

  if (loading) return <p>Verifying your subscription...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      <h2>Subscription Successful!</h2>
      <p>Your subscription has been activated. You can now access all premium features.</p>
      <Link to="/tasks" style={{ marginTop: '1rem', display: 'inline-block', padding: '0.5rem 1rem', backgroundColor: '#4CAF50', color: '#fff', borderRadius: '4px', textDecoration: 'none' }}>
        Go to Tasks
      </Link>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    textAlign: 'center',
  },
  link: {
    marginTop: '1rem',
    display: 'inline-block',
    padding: '0.5rem 1rem',
    backgroundColor: '#4CAF50',
    color: '#fff',
    borderRadius: '4px',
    textDecoration: 'none',
  },
};

export default SuccessPage;