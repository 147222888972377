import { ThemedLayoutV2 } from "@refinedev/antd"
import Header from "./header"
import { Space, Typography } from "antd"
import homepage from "../../assets/homepage.svg"
import React from "react";

const { Title } = Typography;

const CustomTitle: React.FC<{ collapsed: boolean }> = ({ collapsed }) => (
  <Space align="center" style={{ height: '64px' }}> {/* Set a fixed height for the header */}
    <img 
      src={homepage} 
      alt="Expedient Logo" 
      style={{ 
        height: collapsed ? '89px' : '189px',  // Reduced size
        width: 'auto',
        marginRight: collapsed ? -56 : -89,
        objectFit: 'contain',
      }} 
    />
    {!collapsed && (
      <Title 
        level={5} 
        style={{ 
          margin: 0,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '150px'  // Adjust this value as needed
        }}
      >
        {/* expedient */}
      </Title>
    )}
  </Space>
);

const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <ThemedLayoutV2
      Header={Header}
      Title={CustomTitle}
    >
      {children}
    </ThemedLayoutV2>
  )
}

export default Layout