import React from 'react';
import { Layout } from 'antd';
import Footer from '../footer';

const MainLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <Layout>
            <Layout.Content>
                {children}
            </Layout.Content>
            <Footer />
        </Layout>
    );
};

export default MainLayout;