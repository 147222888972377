import { KanbanAddCardButton } from '@/components/tasks/kanban/add-card-button'
import { KanbanBoardContainer, KanbanBoard } from '@/components/tasks/kanban/board'
import ProjectCard from '@/components/tasks/kanban/card'
import KanbanColumn from '@/components/tasks/kanban/column'
import KanbanItem from '@/components/tasks/kanban/item'
import { KanbanColumnSkeleton } from '@/components/tasks/kanban/kanbancolumn-skeleton'
import { ProjectCardSkeleton } from '@/components/skeleton/project-card';
import { UPDATE_TASK_STAGE_MUTATION } from '@/graphql/mutations'
import { TASKS_QUERY, TASKS_STAGES_QUERY } from '@/graphql/queries'
import { Task, TaskStage } from '@/graphql/schema.types'
import { TasksQuery } from '@/graphql/types'
import { useList, useNavigation, useUpdate } from '@refinedev/core'
import { GetFieldsFromList } from '@refinedev/nestjs-query'
import React from 'react'
import { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core'
// import { useDroppable } from '@dnd-kit/core'
// import { useSensors, useSensor, MouseSensor, TouchSensor } from '@dnd-kit/core'

const List = ({ children }: React.PropsWithChildren) => {
    const { replace } = useNavigation()
    
    const { data: stages, isLoading: isLoadingStages } = useList<TaskStage>({
        resource: 'taskStages',
        filters: [
            {
            field: 'title',
            operator: 'in',
            value: ['TODO', 'IN PROGRESS', 'IN REVIEW', 'DONE']
            }
        ],
        sorters: [
            {
                field: 'createdAt',
                order: 'asc'
            }
        ],
        meta: {
            gqlQuery: TASKS_STAGES_QUERY
        }
    })

    const { data: tasks, isLoading: isLoadingTasks } = useList<GetFieldsFromList<TasksQuery>>({
        resource: 'tasks',
        sorters: [
            {
                field: 'dueDate',
                order: 'asc',
            }
        ],
        queryOptions: {
            enabled: !!stages,
        },
        pagination: {
            mode: 'off'
        },
        meta: {
            gqlQuery: TASKS_QUERY
        }
    })

    const { mutate: updateTask } = useUpdate();

    const taskStages = React.useMemo(() => {
        if (!tasks?.data || !stages?.data) {
            return {
                unassignedStage: [],
                stages: []
            }
        }
        const unassignedStage = tasks.data.filter((task) => task.stageId === null)

        const grouped: TaskStage[] = stages.data.map((stage) => ({
            ...stage,
            tasks: tasks.data.filter((task) => task.stageId?.toString() === stage.id) as Task[]
        }))

        return {
            unassignedStage,
            columns: grouped
        }
    }, [tasks, stages])

    const handleAddCard = (args: { stageId: string }) => {
        const path = args.stageId === 'unassigned'
            ? '/tasks/new'
            : `/tasks/new?stageId=${args.stageId}`;
        
        replace(path);
    }

    const handleOnDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        
        if (!active || !over) {
            console.error('Drag event is missing active or over properties');
            return;
        }
    
        console.log('Active id:', active.id);
        console.log('Over id:', over.id);
        console.log('Active data:', active.data.current);
    
        const taskId = active.id;
        let newStageId: UniqueIdentifier | null = over.id;
    
        // Allow 'unassigned' to be treated as null
        if (newStageId === 'unassigned') {
            newStageId = null;
        }
    
        if (taskId == null || (typeof taskId === 'string' && taskId.trim() === '')) {
            console.error('Invalid taskId:', taskId);
            return;
        }
    
        console.log('Updating task:', { taskId, newStageId });
    
        updateTask({
            resource: 'tasks',
            id: taskId,
            values: {
                stageId: newStageId,
                title: active.data.current?.title,
            },
            successNotification: false,
            mutationMode: 'optimistic',
            meta: {
                gqlMutation: UPDATE_TASK_STAGE_MUTATION
            }
        })
    }

    const isLoading = isLoadingStages || isLoadingTasks

    if(isLoading) return <PageSkeleton />

    return (
        <>
            <KanbanBoardContainer>
               <KanbanBoard onDragEnd={handleOnDragEnd}>
                <KanbanColumn
                    id="unassigned"
                    title={"unassigned"}
                    count={taskStages.unassignedStage.length || 0}
                    onAddClick={() => handleAddCard({ stageId: 'unassigned'})}
                >
                    {taskStages.unassignedStage.map((task) => (
                    <KanbanItem key={task.id} id={task.id}
                    data={{...task, stageId: 'unassigned' }}
                    >
                        <ProjectCard
                            {...task}
                            dueDate={task.dueDate || ''}                    
                        />
                    </KanbanItem>
                    ))}
                    {!taskStages.unassignedStage.length && (
                        <KanbanAddCardButton 
                            onClick={() => handleAddCard({ stageId: 'unassigned' })}
                        />
                    )}
                </KanbanColumn>
                {taskStages.columns?.map((column) => (
                    <KanbanColumn
                        key={column.id}
                        id={column.id}
                        title={column.title}
                        count={column.tasks.length}
                        onAddClick={() => handleAddCard({ stageId: column.id})}
                    >
                        {!isLoading && column.tasks.map((task) => (
                            <KanbanItem key={task.id} id={task.id} data={task}>
                                <ProjectCard
                                    {...task}
                                    dueDate={task.dueDate || ''}
                                />
                            </KanbanItem>
                        ))}
                        {!column.tasks.length && (
                            <KanbanAddCardButton 
                            onClick={() => handleAddCard({ stageId: column.id })}                    
                        />
                        )}
                    </KanbanColumn>
                ))}
               </KanbanBoard> 
            </KanbanBoardContainer>
            {children}
        </>
    )
}

export default List

const PageSkeleton = () => {
    const columnCount = 6;
    const itemCount = 4;

    return (
        <KanbanBoardContainer>
            <KanbanBoard onDragEnd={() => { /* Handle drag end in skeleton if needed */ }}>
                {Array.from({ length: columnCount }).map((_, index) => (
                    <KanbanColumnSkeleton key={index}>
                        {Array.from({length: itemCount}).map((_, index)=> (
                        <ProjectCardSkeleton key={index} />
                        ))}
                    </KanbanColumnSkeleton>
                ))}
            </KanbanBoard>
        </KanbanBoardContainer>
    )
}