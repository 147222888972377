import React from 'react';
import { useRegister } from '@refinedev/core';
import { AuthPage } from '@refinedev/antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import welcome from '../../assets/welcome.svg';

export const Register = () => {
  const { mutate: register } = useRegister();
  const navigate = useNavigate();

  const handleRegister = async (values: {
    email: string;
    password: string;
  }) => {
    console.log('Register button clicked!', values); // Debug log
    try {
      console.log('Making API call to:', `${import.meta.env.VITE_API_URL}/auth/register`);
      
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/auth/register`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Registration response:', response.data); // Debug log

      if (response.data?.token) {
        // Store the token
        localStorage.setItem('access_token', response.data.token);
        console.log('Token stored:', response.data.token); // Debug log
        
        // Update auth headers
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        
        // Trigger Refine's register
        console.log('Calling Refine register');
        await register(values);
        
        // Navigate to home
        console.log('Navigating to home');
        navigate('/');
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Registration error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      // Show error to user
      alert(error.response?.data?.message || 'Registration failed');
    }
  };

  return (
    <>
      <img 
        src={welcome} 
        alt="Expedient Logo" 
        style={{ 
          height: '189px',
          width: 'auto',
          objectFit: 'contain',
        }} 
      />
      <AuthPage
        type="register"
        formProps={{
          onFinish: handleRegister,
        }}
      />
    </>
  );
};