import React from 'react';
import { TeamOutlined, BankOutlined, DollarOutlined } from '@ant-design/icons';

// eslint-disable-next-line react-refresh/only-export-components
const IconWrapper: React.FC<{ color: string; children: React.ReactNode }> = ({ color, children }) => (
  <div style={{ 
    backgroundColor: color, 
    borderRadius: '50%', 
    width: '32px', 
    height: '32px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center' 
  }}>
    {children}
  </div>
);

export const totalCountVariants = {
  companies: {
    primaryColor: "#1890FF",
    secondaryColor: "#E6F7FF",
    icon: (
      <IconWrapper color="#E6F7FF">
        <BankOutlined
          className="md"
          style={{
            color: "#1890FF",
          }}
        />
      </IconWrapper>
    ),
    title: "Number of companies",
    data: [
      { index: 1, value: 0 },
      { index: 2, value: 5 },
      { index: 3, value: 15 },
      { index: 4, value: 20 },
      { index: 5, value: 30 }
    ]
  },
  contacts: {
    primaryColor: "#52C41A",
    secondaryColor: "#D9F7B2",
    icon: (
      <IconWrapper color="#F6FFED">
        <TeamOutlined
          className="md"
          style={{
            color: "#52C41A",
          }}
        />
      </IconWrapper>
    ),
    title: "Number of contacts",
    data: [
      { index: 1, value: 0 },
      { index: 2, value: 20 },
      { index: 3, value: 45 },
      { index: 4, value: 70 },
      { index: 5, value: 87 }
    ]
  },
  deals: {
    primaryColor: "#FA541C",
    secondaryColor: "#FFD8BF",
    icon: (
      <IconWrapper color="#FFF2E8">
        <DollarOutlined
          className="md"
          style={{
            color: "#FA541C",
          }}
        />
      </IconWrapper>
    ),
    title: "Total deals in pipeline",
    data: [
      { index: 1, value: 0 },
      { index: 2, value: 50 },
      { index: 3, value: 120 },
      { index: 4, value: 200 },
      { index: 5, value: 278 }
    ]
  }
};

export type TotalCountVariantKey = keyof typeof totalCountVariants;

// Company Size Options
export const companySizeOptions = [
  { label: '1-10 employees', value: '1-10' },
  { label: '11-50 employees', value: '11-50' },
  { label: '51-200 employees', value: '51-200' },
  { label: '201-500 employees', value: '201-500' },
  { label: '501-1000 employees', value: '501-1000' },
  { label: '1001-5000 employees', value: '1001-5000' },
  { label: '5001-10,000 employees', value: '5001-10000' },
  { label: '10,001+ employees', value: '10001+' },
];

// Industry Options
export const industryOptions = [
  { label: 'Technology', value: 'technology' },
  { label: 'Healthcare', value: 'healthcare' },
  { label: 'Finance', value: 'finance' },
  { label: 'Education', value: 'education' },
  { label: 'Manufacturing', value: 'manufacturing' },
  { label: 'Retail', value: 'retail' },
  { label: 'Energy', value: 'energy' },
  { label: 'Transportation', value: 'transportation' },
  { label: 'Agriculture', value: 'agriculture' },
  { label: 'Construction', value: 'construction' },
];

// Status Options
export const statusOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'Qualified',
    value: 'qualified',
  },
  {
    label: 'Unqualified',
    value: 'unqualified',
  },
  {
    label: 'Won',
    value: 'won',
  },
  {
    label: 'Negotiation',
    value: 'negotiation',
  },
  {
    label: 'Lost',
    value: 'lost',
  },
];

// Business Type Options
export const businessTypeOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: 'B2B',
    value: 'B2B',
  },
  {
    label: 'B2C',
    value: 'B2C',
  },
  {
    label: 'B2G',
    value: 'B2G',
  },
];

// Industrial Manufacturing Labels
export const industrialManufacturingLabels = [
  { label: 'Automotive', value: 'automotive' },
  { label: 'Aerospace', value: 'aerospace' },
  { label: 'Electronics', value: 'electronics' },
  { label: 'Machinery', value: 'machinery' },
  { label: 'Chemicals', value: 'chemicals' },
  { label: 'Textiles', value: 'textiles' },
  { label: 'Food Processing', value: 'food_processing' },
  { label: 'Pharmaceuticals', value: 'pharmaceuticals' },
  { label: 'Metals', value: 'metals' },
  { label: 'Plastics', value: 'plastics' },
];